html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  font-family: 'Mukta', sans-serif;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  min-height: 100%; }

html {
  height: 100%; }

body {
  min-height: 100%;
  padding-bottom: 78px; }

@media (max-width: 992px) {
  body {
    padding-bottom: 212px; } }

@media (max-width: 797px) {
  body {
    padding-left: 0;
    padding-right: 0; } }

.container-fluid {
  padding: 0; }
  .container-fluid.padding-container {
    padding: 18px 25px 0; }
  @media (min-width: 576px) {
    .container-fluid.padding-container {
      padding: 40px 100px 0; } }

ul:not(.browser-default) {
  list-style-type: none;
  padding-left: 0; }
  ul:not(.browser-default) > li {
    list-style-type: none; }

a {
  -webkit-tap-highlight-color: transparent; }

.right {
  text-align: right; }

#main-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  min-height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  padding-bottom: 100px; }

:focus {
  outline: transparent none !important; }

.error {
  color: #f18585; }

body {
  background: url("/images/fond.svg") no-repeat center center fixed;
  background-size: cover;
  padding-bottom: 111px; }

.block-spacer {
  padding-left: 30px;
  padding-right: 30px; }

#main-wrapper {
  padding: 0 !important; }

.login__form {
  margin-top: 30px;
  padding-left: 79px;
  padding-right: 79px; }
  .login__form .form {
    margin: auto; }
  .login__form label {
    text-align: left; }
  .login__form .connect {
    margin-top: 40px;
    text-align: center; }
  .login__form a {
    text-decoration: underline; }
  .login__form input[type="checkbox"] {
    margin-top: 14px; }

@media (max-height: 768px) {
  .login__form {
    margin-top: 20px; }
    .login__form .connect {
      margin-top: 20px; } }

@media (max-height: 576px) {
  .login__form {
    margin-top: 10px; }
    .login__form .connect {
      margin-top: 10px; } }

h2 {
  margin-bottom: 40px;
  margin-top: 50px; }

@media (max-height: 768px) {
  h2 {
    margin-top: 15px; } }

@media (max-height: 576px) {
  h2 {
    margin-top: 5px; } }

@media (max-width: 992px) {
  .login__form {
    padding-left: unset;
    padding-right: unset; } }

@media (max-width: 992px) {
  .quit-button {
    margin-top: 37px !important; } }

@media (min-width: 992px) {
  .quit-button {
    float: right;
    margin-right: 90px !important; } }

.bounds-logo {
  height: 117px;
  -o-object-fit: contain;
     object-fit: contain;
  width: 352px; }

.guest-header {
  margin-top: 60px;
  position: relative; }
  .guest-header .previous {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .guest-header .previous i {
      color: #fff;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;
      vertical-align: middle; }
    .guest-header .previous a {
      color: white;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      font-size: 20px;
      font-weight: bold;
      text-decoration: none;
      vertical-align: middle; }
    .guest-header .previous:hover {
      cursor: pointer; }
      .guest-header .previous:hover i {
        margin-right: 10px; }
  .guest-header .center {
    text-align: center; }
    .guest-header .center img {
      margin: auto;
      width: 100%; }

.main-header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  -webkit-box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  overflow: hidden;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 10; }
  .main-header * {
    display: inline; }
  .main-header .burger {
    display: none; }
  .main-header #logo {
    height: auto; }
    @media (max-width: 860px) {
      .main-header #logo {
        max-width: 38%; } }
  .main-header .menu-blue {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 100%; }
    .main-header .menu-blue ul {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: #101847;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      height: 100%;
      list-style-type: none;
      margin: 0;
      overflow: hidden;
      padding: 0; }
      .main-header .menu-blue ul li {
        display: inline;
        height: 100%;
        margin-right: 47px; }
        .main-header .menu-blue ul li a {
          display: block;
          font-family: 'Mukta', sans-serif;
          line-height: 100px;
          margin: 0 32px;
          position: relative;
          text-decoration: none;
          text-transform: uppercase; }
          .main-header .menu-blue ul li a:hover {
            color: white; }
            .main-header .menu-blue ul li a:hover .hover {
              -webkit-transform: scaleX(1);
                      transform: scaleX(1); }
          .main-header .menu-blue ul li a .hover {
            background-image: -webkit-gradient(linear, right top, left top, from(#c4dd3a), to(#ff6b6b));
            background-image: linear-gradient(to left, #c4dd3a, #ff6b6b);
            border-radius: 0 0 4px 4px;
            height: 4px;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            right: 0;
            top: 0;
            -webkit-transform: scaleX(0);
                    transform: scaleX(0);
            -webkit-transition: -webkit-transform 100ms ease-out;
            transition: -webkit-transform 100ms ease-out;
            transition: transform 100ms ease-out;
            transition: transform 100ms ease-out, -webkit-transform 100ms ease-out;
            width: 80px; }
        .main-header .menu-blue ul li:last-child {
          margin-right: 0; }
  .main-header .back-office-link {
    border-bottom: 1px solid #101847;
    margin-left: auto;
    margin-right: 0;
    text-decoration: none; }
    .main-header .back-office-link:hover {
      color: #101847; }
  .main-header #profile {
    margin-left: auto;
    margin-right: 90px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .main-header #profile img {
      border-radius: 20px;
      width: 40px; }
    .main-header #profile .name {
      margin-right: 14px; }
    .main-header #profile i {
      color: #101847;
      font-size: 24px;
      vertical-align: middle; }
  .main-header.scroll {
    -webkit-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.14);
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.14);
    height: 70px; }
    .main-header.scroll .menu-blue > ul > li > a {
      line-height: 70px; }
    .main-header.scroll #logo {
      margin-right: 192px; }

.sidebar {
  display: none; }

#profile {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.profile-menu {
  opacity: 0;
  display: none;
  position: fixed;
  right: 90px;
  top: 84px;
  z-index: 11; }
  .profile-menu.scroll {
    top: 69px; }
  .profile-menu ul {
    background-color: #fff;
    border-radius: 23px;
    -webkit-box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    display: inline-block; }
    .profile-menu ul a {
      text-decoration: none; }
      .profile-menu ul a li {
        cursor: pointer;
        line-height: 1.25;
        padding: 20px 25px 20px 20px;
        white-space: nowrap; }
        .profile-menu ul a li i {
          font-size: 16px;
          margin-left: 10px;
          vertical-align: middle; }
        .profile-menu ul a li:hover {
          background: #101847;
          color: white; }
      .profile-menu ul a:first-child li {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px; }
      .profile-menu ul a:last-child li {
        border-bottom-left-radius: 23px;
        border-bottom-right-radius: 23px; }

@media (max-height: 992px) {
  .guest-header {
    margin-top: 40px; } }

@media (max-height: 768px) {
  .guest-header {
    margin-top: 40px; } }

@media (max-height: 576px) {
  .guest-header {
    margin-top: 30px; } }

@media (max-width: 1200px) {
  .main-header #profile {
    margin-right: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; } }

@media (max-width: 992px) {
  .main-header #logo {
    margin-right: 20px; }
  .main-header ul li a {
    margin: 0 16px !important; }
  .main-header .back-office-link {
    display: none; }
  .main-header #profile {
    margin-right: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .profile-menu {
    right: 6px; } }

@media (max-width: 768px) {
  .main-header {
    height: 57px;
    -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1); }
    .main-header .burger {
      color: #101847;
      cursor: pointer;
      display: inline-block;
      font-family: 'Material Icons', sans-serif;
      font-size: 24px;
      left: 16px;
      line-height: 57px;
      margin: 0;
      outline: 0;
      padding: 0;
      position: absolute;
      text-align: center;
      top: 0;
      width: 24px;
      z-index: 5; }
      .main-header .burger:before {
        content: 'menu'; }
      .main-header .burger.opened:before {
        content: 'close';
        font-size: 24px; }
      .main-header .burger:after {
        content: unset !important; }
    .main-header #logo {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      width: 114px; }
    .main-header .menu-blue img {
      display: none; }
    .main-header .menu-blue .navbar-blue {
      display: none; }
    .main-header #profile {
      margin-right: 16px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .main-header #profile img {
        height: 33px;
        width: 33px; }
  .sidebar.activated-menu {
    background-color: white;
    display: block;
    height: 100%;
    padding-top: 107px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4; }
    .sidebar.activated-menu #background-pattern {
      bottom: 0;
      position: absolute;
      width: 100%; }
    .sidebar.activated-menu .item {
      text-align: center; }
    .sidebar.activated-menu .menu-icon {
      color: #101847;
      font-family: 'Material Icons', sans-serif;
      font-size: 40px;
      height: 40px;
      width: 40px; }
  .profile-menu {
    top: 50px; } }

.logo-container {
  width: 310px;
  margin-left: 60px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 60px; }
  .logo-container img {
    height: auto !important;
    max-width: 100% !important;
    margin: 0 !important; }
  @media (min-width: 768px) {
    .logo-container {
      width: 200px; } }
  @media (max-width: 768px) {
    .logo-container {
      width: 100%;
      margin: 0;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .logo-container a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
      .logo-container img {
        position: relative !important;
        width: 52% !important; } }

.burger-container {
  display: none; }
  @media (max-width: 768px) {
    .burger-container {
      display: inline-block;
      height: 56px;
      width: 56px;
      padding: 16px; } }

.guest-footer * {
  display: inline-block;
  line-height: 31px;
  margin: 0 5px; }

.guest-footer a {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none; }
  .guest-footer a:hover {
    color: #ff6b6b; }

.guest-footer .footer__holder {
  margin: auto; }

.center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.footer {
  bottom: 0;
  padding: 40px 0;
  position: absolute;
  width: 100%; }

.footer__separator {
  background-color: #ffffff;
  height: 31px;
  width: 1px; }

.main-footer {
  background-color: #323232;
  bottom: 0;
  height: 78px;
  padding: 0 100px;
  position: absolute;
  width: 100%; }
  .main-footer .footer__holder {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 100%;
    width: 100%; }
  .main-footer .left-links {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
    .main-footer .left-links a {
      color: white;
      margin: auto 10px;
      text-decoration: none; }
      .main-footer .left-links a:first-child {
        margin-left: 0; }
  .main-footer .right-social {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: auto;
    margin-right: 0; }
    .main-footer .right-social .footer__separator {
      margin-left: 30px;
      margin-right: 31px; }
    .main-footer .right-social .social-network {
      background-color: white;
      border-radius: 15px;
      height: 30px;
      margin-left: 16px;
      width: 30px; }

@media (max-width: 992px) {
  .main-footer {
    height: 212px;
    padding: 16px 0 30px; }
    .main-footer .footer__holder {
      display: block;
      text-align: center; }
    .main-footer .m--margin-top-10 {
      margin-top: 10px; }
    .main-footer .m--margin-top-20 {
      margin-top: 20px; } }

.btn {
  border: 0;
  cursor: pointer;
  display: block;
  font-family: 'Mukta', sans-serif !important;
  height: auto;
  line-height: 1;
  margin: 0 auto;
  outline: 0;
  position: relative;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent; }
  .btn:focus {
    outline: none; }

.btn--primary, .btn--secondary {
  background: transparent;
  border: 0 !important;
  border-radius: 21px !important;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  height: 42px;
  padding: 4px 30px !important;
  text-align: center;
  text-transform: uppercase; }
  .btn--primary span, .btn--secondary span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 21px;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 20px;
    font-weight: bold !important;
    height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%; }

.btn--filters {
  background: transparent;
  border: 1px solid #101847;
  border-radius: 6px;
  color: #101847;
  font-family: 'Mukta', sans-serif;
  font-size: 14px;
  height: 26px;
  padding: 0 14px; }
  .btn--filters:focus {
    outline: none; }
  .btn--filters:hover:not(.no-hover) {
    background: #101847;
    color: white !important;
    cursor: pointer; }
  .btn--filters.selected, .btn--filters:checked {
    background: #101847;
    color: white !important; }

.btn--appbar {
  background-color: #77CACD;
  border-radius: 0 !important;
  color: white;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 58px;
  padding: 0 !important; }
  .btn--appbar .center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 17px 60px; }
  .btn--appbar i {
    margin-right: 12px; }
  .btn--appbar:hover {
    background-color: white;
    border: 1px solid #77CACD;
    color: #77CACD;
    cursor: pointer; }

.btn--rounded-blue {
  background-color: #77cacd;
  border-radius: 50%;
  color: #fff;
  padding: 18px !important;
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 16px; }
  .btn--rounded-blue i {
    display: block; }
  .btn--rounded-blue:hover {
    background-color: #fff;
    border: 1px solid #77cacd;
    color: #77cacd; }

.btn--slider-arrow {
  background-color: #101847;
  border: 1px solid #101847;
  border-radius: 0 !important;
  color: white;
  font-size: 40px !important;
  font-weight: 600 !important;
  padding: 0 !important;
  margin-left: 10px; }
  .btn--slider-arrow .center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 12px; }
  .btn--slider-arrow i {
    font-size: 40px !important; }
  .btn--slider-arrow:hover:not(.slick-disabled) {
    background-color: white;
    color: #101847;
    cursor: pointer; }
  .btn--slider-arrow.slick-disabled {
    opacity: .5; }

.gradient-button {
  border-radius: 21px;
  margin: auto;
  -webkit-transition: none !important;
  transition: none !important;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  background-size: 102% !important;
  background-position: -1px !important; }
  .gradient-button--orange {
    background: -webkit-gradient(linear, left top, right top, from(#c4dd3a), to(#ff6b6b));
    background: linear-gradient(to right, #c4dd3a, #ff6b6b); }
    .gradient-button--orange:hover {
      background: -webkit-gradient(linear, left top, right top, from(#101847), to(#101847));
      background: linear-gradient(to right, #101847, #101847);
      border: 1px solid #ffffff !important; }
  .gradient-button--blue {
    background: -webkit-gradient(linear, left top, left bottom, from(#25357d), to(#101847));
    background: linear-gradient(to bottom, #25357d, #101847); }
    .gradient-button--blue:hover {
      background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fff));
      background: linear-gradient(to right, #fff, #fff);
      border: 1px solid #101847 !important; }
      .gradient-button--blue:hover span {
        color: #101847; }
  .gradient-button--transparent {
    border: 1px solid #fff !important; }
    .gradient-button--transparent:hover {
      background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
      background: linear-gradient(to bottom, #fff, #fff); }
      .gradient-button--transparent:hover span {
        color: #101847; }
  .gradient-button--white {
    border: 1px solid #101847 !important;
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
    background: linear-gradient(to bottom, #fff, #fff); }
    .gradient-button--white span {
      color: #101847; }
    .gradient-button--white:hover {
      background: -webkit-gradient(linear, left top, left bottom, from(#25357d), to(#101847));
      background: linear-gradient(to bottom, #25357d, #101847); }
      .gradient-button--white:hover span {
        color: #fff; }

.form {
  width: 100%; }

.input-field {
  position: relative; }
  .input-field input[type="text"], .input-field input[type="email"], .input-field input[type="date"], .input-field input[type="datetime-local"],
  .input-field input[type="number"], .input-field input[type="password"], .input-field input[type="url"], .input-field input[type="tel"], .input-field input[type="search"] {
    background-color: white;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #323232;
    display: block;
    font-family: 'Mukta', sans-serif;
    font-size: 20px;
    height: 52px;
    outline: none !important;
    padding-left: 20px;
    width: 100%; }
    .input-field input[type="text"]::-webkit-input-placeholder, .input-field input[type="email"]::-webkit-input-placeholder, .input-field input[type="date"]::-webkit-input-placeholder, .input-field input[type="datetime-local"]::-webkit-input-placeholder,
    .input-field input[type="number"]::-webkit-input-placeholder, .input-field input[type="password"]::-webkit-input-placeholder, .input-field input[type="url"]::-webkit-input-placeholder, .input-field input[type="tel"]::-webkit-input-placeholder, .input-field input[type="search"]::-webkit-input-placeholder {
      color: #a4a4a4; }
    .input-field input[type="text"]::-ms-input-placeholder, .input-field input[type="email"]::-ms-input-placeholder, .input-field input[type="date"]::-ms-input-placeholder, .input-field input[type="datetime-local"]::-ms-input-placeholder,
    .input-field input[type="number"]::-ms-input-placeholder, .input-field input[type="password"]::-ms-input-placeholder, .input-field input[type="url"]::-ms-input-placeholder, .input-field input[type="tel"]::-ms-input-placeholder, .input-field input[type="search"]::-ms-input-placeholder {
      color: #a4a4a4; }
    .input-field input[type="text"]::placeholder, .input-field input[type="email"]::placeholder, .input-field input[type="date"]::placeholder, .input-field input[type="datetime-local"]::placeholder,
    .input-field input[type="number"]::placeholder, .input-field input[type="password"]::placeholder, .input-field input[type="url"]::placeholder, .input-field input[type="tel"]::placeholder, .input-field input[type="search"]::placeholder {
      color: #a4a4a4; }
    .input-field input[type="text"]:disabled, .input-field input[type="text"]:-moz-read-only, .input-field input[type="email"]:disabled, .input-field input[type="email"]:-moz-read-only, .input-field input[type="date"]:disabled, .input-field input[type="date"]:-moz-read-only, .input-field input[type="datetime-local"]:disabled, .input-field input[type="datetime-local"]:-moz-read-only,
    .input-field input[type="number"]:disabled,
    .input-field input[type="number"]:-moz-read-only, .input-field input[type="password"]:disabled, .input-field input[type="password"]:-moz-read-only, .input-field input[type="url"]:disabled, .input-field input[type="url"]:-moz-read-only, .input-field input[type="tel"]:disabled, .input-field input[type="tel"]:-moz-read-only, .input-field input[type="search"]:disabled, .input-field input[type="search"]:-moz-read-only {
      background-color: #9b9b9b; }
    .input-field input[type="text"]:disabled, .input-field input[type="text"]:read-only, .input-field input[type="email"]:disabled, .input-field input[type="email"]:read-only, .input-field input[type="date"]:disabled, .input-field input[type="date"]:read-only, .input-field input[type="datetime-local"]:disabled, .input-field input[type="datetime-local"]:read-only,
    .input-field input[type="number"]:disabled,
    .input-field input[type="number"]:read-only, .input-field input[type="password"]:disabled, .input-field input[type="password"]:read-only, .input-field input[type="url"]:disabled, .input-field input[type="url"]:read-only, .input-field input[type="tel"]:disabled, .input-field input[type="tel"]:read-only, .input-field input[type="search"]:disabled, .input-field input[type="search"]:read-only {
      background-color: #9b9b9b; }
      .input-field input[type="text"]:disabled::-webkit-input-placeholder, .input-field input[type="text"]:read-only::-webkit-input-placeholder, .input-field input[type="email"]:disabled::-webkit-input-placeholder, .input-field input[type="email"]:read-only::-webkit-input-placeholder, .input-field input[type="date"]:disabled::-webkit-input-placeholder, .input-field input[type="date"]:read-only::-webkit-input-placeholder, .input-field input[type="datetime-local"]:disabled::-webkit-input-placeholder, .input-field input[type="datetime-local"]:read-only::-webkit-input-placeholder,
      .input-field input[type="number"]:disabled::-webkit-input-placeholder,
      .input-field input[type="number"]:read-only::-webkit-input-placeholder, .input-field input[type="password"]:disabled::-webkit-input-placeholder, .input-field input[type="password"]:read-only::-webkit-input-placeholder, .input-field input[type="url"]:disabled::-webkit-input-placeholder, .input-field input[type="url"]:read-only::-webkit-input-placeholder, .input-field input[type="tel"]:disabled::-webkit-input-placeholder, .input-field input[type="tel"]:read-only::-webkit-input-placeholder, .input-field input[type="search"]:disabled::-webkit-input-placeholder, .input-field input[type="search"]:read-only::-webkit-input-placeholder {
        color: #101847; }
      .input-field input[type="text"]:disabled::-ms-input-placeholder, .input-field input[type="text"]:read-only::-ms-input-placeholder, .input-field input[type="email"]:disabled::-ms-input-placeholder, .input-field input[type="email"]:read-only::-ms-input-placeholder, .input-field input[type="date"]:disabled::-ms-input-placeholder, .input-field input[type="date"]:read-only::-ms-input-placeholder, .input-field input[type="datetime-local"]:disabled::-ms-input-placeholder, .input-field input[type="datetime-local"]:read-only::-ms-input-placeholder,
      .input-field input[type="number"]:disabled::-ms-input-placeholder,
      .input-field input[type="number"]:read-only::-ms-input-placeholder, .input-field input[type="password"]:disabled::-ms-input-placeholder, .input-field input[type="password"]:read-only::-ms-input-placeholder, .input-field input[type="url"]:disabled::-ms-input-placeholder, .input-field input[type="url"]:read-only::-ms-input-placeholder, .input-field input[type="tel"]:disabled::-ms-input-placeholder, .input-field input[type="tel"]:read-only::-ms-input-placeholder, .input-field input[type="search"]:disabled::-ms-input-placeholder, .input-field input[type="search"]:read-only::-ms-input-placeholder {
        color: #101847; }
      .input-field input[type="text"]:disabled::-moz-placeholder, .input-field input[type="text"]:read-only::-moz-placeholder, .input-field input[type="email"]:disabled::-moz-placeholder, .input-field input[type="email"]:read-only::-moz-placeholder, .input-field input[type="date"]:disabled::-moz-placeholder, .input-field input[type="date"]:read-only::-moz-placeholder, .input-field input[type="datetime-local"]:disabled::-moz-placeholder, .input-field input[type="datetime-local"]:read-only::-moz-placeholder,
      .input-field input[type="number"]:disabled::-moz-placeholder,
      .input-field input[type="number"]:read-only::-moz-placeholder, .input-field input[type="password"]:disabled::-moz-placeholder, .input-field input[type="password"]:read-only::-moz-placeholder, .input-field input[type="url"]:disabled::-moz-placeholder, .input-field input[type="url"]:read-only::-moz-placeholder, .input-field input[type="tel"]:disabled::-moz-placeholder, .input-field input[type="tel"]:read-only::-moz-placeholder, .input-field input[type="search"]:disabled::-moz-placeholder, .input-field input[type="search"]:read-only::-moz-placeholder {
        color: #101847; }
      .input-field input[type="text"]:disabled::placeholder, .input-field input[type="text"]:read-only::placeholder, .input-field input[type="email"]:disabled::placeholder, .input-field input[type="email"]:read-only::placeholder, .input-field input[type="date"]:disabled::placeholder, .input-field input[type="date"]:read-only::placeholder, .input-field input[type="datetime-local"]:disabled::placeholder, .input-field input[type="datetime-local"]:read-only::placeholder,
      .input-field input[type="number"]:disabled::placeholder,
      .input-field input[type="number"]:read-only::placeholder, .input-field input[type="password"]:disabled::placeholder, .input-field input[type="password"]:read-only::placeholder, .input-field input[type="url"]:disabled::placeholder, .input-field input[type="url"]:read-only::placeholder, .input-field input[type="tel"]:disabled::placeholder, .input-field input[type="tel"]:read-only::placeholder, .input-field input[type="search"]:disabled::placeholder, .input-field input[type="search"]:read-only::placeholder {
        color: #101847; }
  .input-field select:first-child {
    color: #a4a4a4; }
  .input-field label, .input-field legend {
    display: block;
    font-family: 'Mukta', sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 6px;
    margin-top: 20px;
    padding: 0; }
  .input-field a {
    font-size: 14px;
    line-height: 1.07; }
    .input-field a:hover {
      cursor: pointer; }
  .input-field.validated input {
    border: 1px solid #67e269 !important;
    color: #67e269; }
  .input-field.validated::after {
    color: #67e269;
    content: "\e5ca";
    font-family: 'Material Icons', sans-serif;
    font-size: 20px;
    position: absolute;
    right: 20px;
    top: 40px; }
  .input-field.error input {
    border: 1px solid #f18585 !important;
    color: #f18585; }
  .input-field.error::after {
    color: #f18585;
    content: "\e5cd";
    font-family: 'Material Icons', sans-serif;
    font-size: 20px;
    position: absolute;
    right: 20px;
    top: 40px; }
  .input-field input:focus {
    outline: none; }
  .input-field .bg-input.show {
    background: -webkit-gradient(linear, left top, right top, from(#c4dd3a), to(#ff6b6b));
    background: linear-gradient(to right, #c4dd3a, #ff6b6b);
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    outline: none;
    padding: 1px;
    -webkit-transform: translate3d(0, 0, 0); }
    .input-field .bg-input.show input {
      border: none;
      height: 50px; }
  .input-field.searchbar input {
    font-size: 16px;
    height: 36px;
    padding-left: 36px; }
  .input-field.searchbar::after {
    bottom: 7px;
    color: #101847;
    content: "\e8b6";
    font-family: 'Material Icons', sans-serif;
    font-size: 16px;
    left: 10px;
    position: absolute; }
  .input-field.searchbar .bg-input.show input {
    height: 34px; }
  .input-field.searchbar-big .checkbox {
    height: 50px;
    position: absolute;
    right: 20px; }
    .input-field.searchbar-big .checkbox label {
      text-indent: unset; }
  .input-field .bootstrap-select {
    background-color: #ffffff;
    border-radius: 4px;
    height: 54px;
    width: 100% !important; }
    .input-field .bootstrap-select.show {
      background: -webkit-gradient(linear, left top, right top, from(#c4dd3a), to(#ff6b6b));
      background: linear-gradient(to right, #c4dd3a, #ff6b6b);
      border: none;
      border-radius: 4px;
      -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
              box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
      outline: none;
      padding: 1px 1px 0 1px;
      -webkit-transform: translate3d(0, 0, 0);
      z-index: 3; }
      .input-field .bootstrap-select.show button {
        background-color: white;
        border-color: white; }
        .input-field .bootstrap-select.show button::after {
          content: '\e316'; }
    .input-field .bootstrap-select .filter-option-inner {
      padding-right: 30px; }
    .input-field .bootstrap-select .filter-option-inner-inner {
      font-family: 'Mukta', sans-serif;
      font-size: 20px;
      margin-top: 12px; }
    .input-field .bootstrap-select .dropdown-toggle {
      background-color: white;
      height: 52px;
      outline: 0;
      padding-left: 20px; }
      .input-field .bootstrap-select .dropdown-toggle:focus {
        border: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to right, #c4dd3a, #ff6b6b);
        border-radius: 4px;
        -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
        outline: none;
        -webkit-transform: translate3d(0, 0, 0); }
      .input-field .bootstrap-select .dropdown-toggle::after {
        border: none;
        color: #4a4a4a;
        content: "\e313";
        font-family: 'Material Icons', sans-serif;
        font-size: 20px;
        position: absolute;
        right: 20px;
        top: 18px;
        width: 20px; }
      .input-field .bootstrap-select .dropdown-toggle.bs-placeholder {
        color: #a4a4a4 !important; }
    .input-field .bootstrap-select div.dropdown-menu {
      min-width: calc(100% + 2px); }
    .input-field .bootstrap-select .dropdown-menu {
      left: -2px !important;
      padding: 0;
      top: -2px !important; }
      .input-field .bootstrap-select .dropdown-menu li {
        height: 64px; }
        .input-field .bootstrap-select .dropdown-menu li.active, .input-field .bootstrap-select .dropdown-menu li:hover {
          background-color: #101847; }
        .input-field .bootstrap-select .dropdown-menu li .text {
          color: #323232;
          font-family: 'Mukta', sans-serif;
          font-size: 20px;
          line-height: 52px; }
        .input-field .bootstrap-select .dropdown-menu li .dropdown-item:hover, .input-field .bootstrap-select .dropdown-menu li .dropdown-item.active, .input-field .bootstrap-select .dropdown-menu li .dropdown-item:active {
          background-color: #101847; }
          .input-field .bootstrap-select .dropdown-menu li .dropdown-item:hover .text, .input-field .bootstrap-select .dropdown-menu li .dropdown-item:hover .check-mark, .input-field .bootstrap-select .dropdown-menu li .dropdown-item.active .text, .input-field .bootstrap-select .dropdown-menu li .dropdown-item.active .check-mark, .input-field .bootstrap-select .dropdown-menu li .dropdown-item:active .text, .input-field .bootstrap-select .dropdown-menu li .dropdown-item:active .check-mark {
            color: white; }
        .input-field .bootstrap-select .dropdown-menu li.no-results {
          background-color: white;
          font-family: 'Mukta', sans-serif;
          margin-left: 20px;
          padding-left: 0; }
    .input-field .bootstrap-select.show-tick .dropdown-item {
      padding-left: 50px; }
      .input-field .bootstrap-select.show-tick .dropdown-item.selected .bs-ok-default:before {
        content: '\e834'; }
    .input-field .bootstrap-select.show-tick span.check-mark {
      color: #101847;
      display: inline-block;
      left: 20px !important;
      position: absolute !important;
      top: 20px !important; }
    .input-field .bootstrap-select.show-tick .bs-ok-default:before {
      border-width: 0 !important;
      content: '\e835';
      font-family: 'Material Icons', sans-serif;
      font-size: 20px;
      -webkit-transform: none;
              transform: none; }
    .input-field .bootstrap-select.show-tick .bs-ok-default:after {
      all: initial; }
      .input-field .bootstrap-select.show-tick .bs-ok-default:after * {
        all: unset; }
    .input-field .bootstrap-select .bs-searchbox {
      margin: 16px 20px;
      padding: 0; }
      .input-field .bootstrap-select .bs-searchbox input {
        border-color: #a4a4a4;
        height: 48px; }
      .input-field .bootstrap-select .bs-searchbox.show input {
        border-color: #101847;
        -webkit-box-shadow: none !important;
                box-shadow: none !important;
        outline: none !important; }
  .input-field.white label, .input-field.white a {
    color: white; }
  .input-field.white .dropdown-menu li.active, .input-field.white .dropdown-menu li:hover {
    background-color: #77cacd !important; }
  .input-field.white .dropdown-menu .dropdown-item:hover, .input-field.white .dropdown-menu .dropdown-item.active, .input-field.white .dropdown-menu .dropdown-item:active {
    background-color: #77cacd !important; }
  .input-field.black input {
    border: 1px solid #101847; }
  .input-field.black label {
    color: #323232; }
  .input-field.black .dropdown {
    border: 1px solid #101847; }
    .input-field.black .dropdown.show {
      border: 0; }

.security-message {
  font-size: 80%; }
  .security-message--error {
    color: #dc3545;
    margin-top: .25rem; }
  .security-message--success {
    color: #36dc70;
    margin-top: .25rem; }

.form-searchbar .filter-option-inner-inner {
  margin-top: 0; }

.checkbox label {
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  padding-left: 15px;
  text-indent: -15px;
  vertical-align: middle; }

.checkbox:hover {
  cursor: pointer; }

input[type="checkbox"], input[type="radio"] {
  border-radius: 2px;
  display: inline-block;
  margin: 0 8px 0 0;
  overflow: hidden;
  padding: 8px;
  position: relative;
  top: -8px;
  vertical-align: middle;
  -webkit-appearance: none; }
  input[type="checkbox"].white, input[type="radio"].white {
    background: transparent;
    border: 1px solid #ffffff; }
    input[type="checkbox"].white:checked:after, input[type="radio"].white:checked:after {
      font-size: 16px;
      color: #101847;
      font-family: 'Material Icons', sans-serif;
      content: '\e5ca';
      left: 4px;
      position: absolute; }
  input[type="checkbox"]:checked, input[type="radio"]:checked {
    background-color: white;
    overflow: visible; }
    input[type="checkbox"]:checked:after, input[type="radio"]:checked:after {
      font-size: 24px;
      color: #101847;
      font-family: 'Material Icons', sans-serif;
      content: 'check_box';
      left: 0;
      top: 0;
      position: absolute;
      margin: -4px; }
  input[type="checkbox"]:focus, input[type="radio"]:focus {
    outline: 0; }

.form-check {
  padding-left: 0; }
  .form-check label {
    width: calc(100% - 30px); }
  .form-check input[type=checkbox], .form-check input[type=radio] {
    margin-top: 28px; }

.mobile-form input[type=checkbox]:after, .mobile-form input[type=radio]:after {
  left: 1px;
  top: -6px; }

.radio {
  display: inline-block;
  margin-right: 20px;
  position: relative; }
  .radio label {
    display: inline-block;
    margin-left: 24px;
    margin-top: 0; }
  .radio input[type="radio"] {
    border: none !important;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    width: 16px;
    position: absolute;
    padding: 0;
    top: 5px;
    left: 0;
    -webkit-appearance: none; }
    .radio input[type="radio"]:focus {
      outline: 0; }
    .radio input[type="radio"]:not(checked) {
      font-family: 'Material Icons', sans-serif;
      font-size: 16px;
      background: none; }
      .radio input[type="radio"]:not(checked):after {
        color: #ffffff;
        content: '\e836'; }
    .radio input[type="radio"]:checked {
      font-family: 'Material Icons', sans-serif;
      font-size: 16px;
      background: none; }
      .radio input[type="radio"]:checked:after {
        color: #ffffff;
        content: '\e837';
        top: 0; }

h1 {
  font-size: 46px;
  font-weight: 600;
  line-height: 47px; }

h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 47px; }

h1.white, h2.white {
  color: #ffffff; }

h1.blue, h2.blue {
  color: #101847; }

h1.center, h2.center {
  text-align: center; }

.md-16 {
  font-size: 16px !important; }

.md-32 {
  font-size: 32px !important; }

.menu {
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center; }
  .menu.active {
    font-size: 30px;
    font-weight: 800; }
  .menu.select {
    font-weight: 900; }
  .menu.mobile {
    font-weight: 800;
    text-transform: uppercase; }

.body {
  font-family: 'Mukta', sans-serif;
  font-size: 14px;
  line-height: 15px; }
  .body.semibold {
    line-height: 21px; }

.semibold {
  font-weight: 600; }

.body2 {
  font-family: 'Mukta', sans-serif;
  line-height: 1.31;
  font-size: 16px !important; }

.subheading {
  font-family: 'Mukta', sans-serif;
  font-size: 24px;
  line-height: 32px; }

.white {
  color: #ffffff; }

.blue {
  color: #101847; }

.lightblue {
  color: #77cacd; }

.red {
  color: #ff6b6b; }

.left {
  text-align: left; }

.centered {
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

p {
  margin-bottom: 4px; }

@media (max-width: 768px) {
  h2 {
    font-size: 24px;
    line-height: 30px; }
  .subheading {
    font-size: 16px; }
  .menu {
    font-size: 14px; } }

.timeline {
  position: absolute;
  top: 60px;
  right: 100px; }
  .timeline * * {
    margin: auto; }

.timeline-wrapper {
  min-height: 600px; }

.step {
  position: relative;
  height: 42px;
  margin: 14px 0; }
  .step:first-child {
    margin-top: 0; }
  .step:last-child {
    margin-bottom: 0; }
  .step-active {
    margin: 14px 0;
    position: relative; }
    .step-active span {
      position: absolute;
      top: 16px;
      right: 36px;
      height: 50px; }
  .step img {
    position: absolute;
    right: 21px; }
  .step span {
    position: absolute;
    top: 6px;
    right: 38px;
    width: 12px; }

.step-number:hover {
  cursor: pointer; }

.path * {
  display: block; }

.path .line-transparent {
  width: 1px;
  height: 37px;
  opacity: 0.3;
  margin-top: 7px;
  margin-bottom: 7px;
  background-image: -webkit-gradient(linear, left top, right top, from(#c4dd3a), to(#ff6b6b));
  background-image: linear-gradient(to right, #c4dd3a, #ff6b6b); }

.path .line-filled {
  width: 1px;
  height: 37px;
  opacity: 1;
  margin-top: 7px;
  margin-bottom: 7px;
  background-image: -webkit-gradient(linear, left top, right top, from(#c4dd3a), to(#ff6b6b));
  background-image: linear-gradient(to right, #c4dd3a, #ff6b6b); }

.bg-item {
  display: none;
  position: absolute;
  right: 89px;
  top: -3px;
  height: 47px;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 10px 20px; }
  .bg-item p {
    clear: both;
    display: inline-block;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap; }

.container-btn-skip {
  z-index: 3;
  position: absolute;
  bottom: 40px;
  right: 101px; }

@media (max-height: 768px) {
  .timeline-wrapper {
    min-height: 580px; }
  .timeline {
    top: 40px; } }

@media (max-width: 568px) {
  .timeline {
    display: none; } }

.slider .slider-handle {
  background-color: #ffffff !important;
  background-image: unset;
  border-radius: 4px !important;
  height: 25px;
  width: 14px; }

.slider.slider-horizontal {
  width: 100% !important; }

.slider .slider-selection {
  background-image: -webkit-gradient(linear, left top, right top, from(#c4dd3a), to(#ff6b6b));
  background-image: linear-gradient(to right, #c4dd3a, #ff6b6b); }

.slider .slider-track {
  background: transparent;
  height: 14px !important; }
  .slider .slider-track-high {
    border: 1px solid white; }

.slider-items {
  margin-top: 12px;
  margin-left: -17%;
  margin-right: -17%; }
  .slider-items .slider-item {
    margin: 0 !important;
    text-align: center; }
    .slider-items .slider-item p:first-child {
      margin-bottom: 0; }
    .slider-items .slider-item p:not(:first-child) {
      font-family: 'Mukta', sans-serif;
      font-size: 12px;
      line-height: 1.75; }
